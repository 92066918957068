/* Basic CSS Reset */
html,
body {
  box-sizing: border-box;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

/* end Basic CSS Reset */

body {
  font-family: Helvetica, sans-serif;
  background-color: #f9f9f9;
}

/* PADDINGS */
.p-t-0 {
  padding-top: 0px;
}

.p-r-0 {
  padding-right: 0px;
}

.p-b-0 {
  padding-bottom: 0px;
}

.p-l-0 {
  padding-left: 0px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-b-5 {
  padding-bottom: 5px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-l-20 {
  padding-left: 20px;
}

/* MARGINS */

.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-l-0 {
  margin-left: 0px;
}

.m-l-5 {
  margin-left: 5px;
}

.m-l-10 {
  margin-left: 10px;
}

.m-l-15 {
  margin-left: 15px;
}

.m-l-20 {
  margin-left: 20px;
}

.m-l-25 {
  margin-left: 25px;
}

.m-l-30 {
  margin-left: 30px;
}

.m-l-45 {
  margin-left: 45px;
}

.m-t-0 {
  margin-top: 0px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-t-25 {
  margin-top: 25px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-t-45 {
  margin-top: 45px;
}

.m-b-0 {
  margin-bottom: 0px;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-25 {
  margin-bottom: 25px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-40 {
  margin-bottom: 30px;
}

.m-b-45 {
  margin-bottom: 45px;
}

/* FLEXBOX */
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-flex-1 {
  flex: 1 1;
}

/* FLEXBOX - Align items */
.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

/* FLEXBOX - Direction */
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

/* FLEXBOX - Wrap */
.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

/* FLEXBOX - Justify Content */
.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

/* POSITIONS */
.position-relative {
  position: relative;
}

/* DATEPICKER HACK
/* ONLY SHOW THE INPUT VALUE WITHOUT DROPDOWN */
.date-picker-hidden-dropdown {
  display: none !important;
}

/* FONT STYLES */
.text-decoration-none {
  text-decoration: none;
}

.no-wrap {
  white-space: nowrap;
}

/* SIZING */
.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

/* TEXT WRAPPING AND OVERFLOW */
.text-nowrap {
  white-space: nowrap;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* MOUSE CURSOR */
.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

*:focus {
  outline-color: rgba(11, 201, 238, 0.84);
}

/* TOOLTIP */
.__react_component_tooltip.show {
  opacity: 1;
}

/* OTHER */
.sectionWrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
}

.hide {
  display: none;
}

/* @media only screen and (max-width:768px){
   .mainRoot {
    margin-top: 5%;
    }
}

@media only screen and (max-width:578px){
  .mainRoot {
   margin-top: 8%;
   }
} */
/* 
@media only screen and (max-width:430px){
  .mainRoot {
   margin-top: 10%;
   }
} */
/* IE FIXES */

::-ms-clear {
  display: none;
}

/* input[type="text"][disabled] {
  background-color: rgb(250, 250, 250);
} */

input[disabled=""] {
  background-color: rgb(250, 250, 250);
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.twoLineText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.twoLineText span {
  vertical-align: middle;
}

/* input[disabled="disabled"] {
  background-color: rgb(250, 250, 250);
} */

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
  height: 3px;
  background: #F5F5F5;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #4442422d;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #0bc9f6;
} */

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgba(64, 64, 64, 0.13);
}

::-webkit-scrollbar-thumb {
  background-color: #0bc9f6;
  background-clip: padding-box;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(112, 112, 112);
}

::-webkit-scrollbar-thumb:active {
  background-color: rgb(128, 128, 128);
}

/* Buttons */
::-webkit-scrollbar-button:single-button {
  background-color: rgba(64, 64, 64, 0.151);
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 12px;
  width: 16px;
  background-position: center 4px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* down */
/* ::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 12px;
  width: 16px;
  background-position: center 4px;
   background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
} */
input[type="checkbox"] {
  accent-color: #0bc9f6;
}
.flex_cols {
  display: flex;
  flex-direction: column;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 1%;
  border: 1px solid #888;
  width: 45%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.success-animation { margin:31px auto}

.checkmark {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}
